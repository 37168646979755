<template>
  <div>
    <PageHeader :items="items" />
    <validation-observer ref="simpleRules">
      <div class="card">
        <div class="card-body">
          <b-row>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <validation-provider
                #default="{ errors }"
                name="Subject"
                rules="required"
              >
                <TextInput
                  v-model="form.subject"
                  :label="$t('Subject')"
                  :tooltipMessage="$t('Subject')"
                  :required="true"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <validation-provider
                #default="{ errors }"
                name="Language"
                rules="required"
              >
                <label class="input-label form-label"
                  ><span style="color: red">*</span>&nbsp;{{ $t("Language") }}
                </label>
                <multiselect
                  style="z-index: 9999"
                  v-model="form.language"
                  :options="['english', 'french', 'german', 'spanish']"
                  :multiple="false"
                >
                </multiselect>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <label class="input-label form-label"
                ><span v-show="form.isHighlight" style="color: red">*</span
                >&nbsp;{{ $t("Thumbnail") }}
              </label>
              <input
                type="file"
                @change="
                  (event) => {
                    addFile(event, 'thumbnail');
                  }
                "
                accept="image/*"
              />
              <div class="news-image" v-if="thumbnail.url">
                <img :src="thumbnail.url" />
              </div>
            </b-col>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <label class="input-label form-label">{{ $t("Image") }} </label>
              <input
                type="file"
                @change="addFile($event, 'image')"
                accept="image/*"
              />
              <div class="news-image" v-if="file.url">
                <img :src="file.url" />
              </div>
            </b-col>
            <b-col cols="12" class="mb-1">
              <validation-provider
                #default="{ errors }"
                name="Short Description"
                :rules="{ required: form.isHighlight }"
              >
                <TextInput
                  v-model="form.shortDescription"
                  :label="$t('Short Description')"
                  :tooltipMessage="$t('Short Description')"
                  :required="form.isHighlight"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="12" class="mb-1">
              <label class="input-label form-label" for="cars">{{
                $t("Highlight")
              }}</label>

              <input
                id="active"
                class="form-check-input-custom ml-2"
                v-model="form.isHighlight"
                type="checkbox"
              />
            </b-col>
            <b-col cols="12">
              <validation-provider
                #default="{ errors }"
                name="Description"
                rules="required"
              >
                <label class="input-label form-label"
                  ><span style="color: red">*</span>&nbsp;{{
                    $t("Description")
                  }}
                </label>
                <vue-editor v-model="form.description"></vue-editor>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-end gap-2">
        <router-link
          to="/news"
          class="d-flex align-items-center gap-1 btn btn-secondary"
        >
          <feather-icon icon="XIcon" size="12" />
          <span>{{ $t("Cancel") }}</span>
        </router-link>
        <b-button
          variant="primary"
          @click="updateNews"
          :class="{ 'cursor-not-allowed': isFormInvalid }"
          class="d-flex align-items-center gap-1"
          v-if="$can(`${$route.meta.permission}.edit`)"
        >
          <feather-icon icon="PlusIcon" size="12" />
          <span>{{ $t("Update News") }}</span>
        </b-button>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import TextInput from "@/components/TextInput.vue";
import PageHeader from "@/components/PageHeader.vue";
import Multiselect from "vue-multiselect";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapGetters } from "vuex";
import { VueEditor } from "vue2-quill-editor";
import { required, email } from "@validations";

export default {
  components: {
    TextInput,
    PageHeader,
    Multiselect,
    ValidationProvider,
    ValidationObserver,
    VueEditor,
  },
  computed: {
    items() {
      return [
        {
          text: "Dental Twin",
          to: "/home",
        },
        {
          text: this.$t("News"),
          to: "/news",
        },
        {
          text: this.$t("Edit"),
          active: true,
        },
      ];
    },
    isFormInvalid() {
      return (
        !this.form.subject ||
        !this.form.language ||
        !this.form.description ||
        (this.form.isHighlight && !this.form.thumbnailImage) ||
        (this.form.isHighlight && !this.form.shortDescription)
      );
    },
  },
  data() {
    return {
      form: {
        subject: "",
        description: "",
        language: "english",
        shortDescription: "",
        isHighlight: false,
        thumbnailImage: {},
        file: {},
      },
      file: {},
      thumbnail: {},
    };
  },
  async mounted() {
    try {
      this.$store.commit("showLoader", true);
      await this.$store
        .dispatch("news/show", this.$route.params.id)
        .then(async (res) => {
          var response = res?.data?.data;
          this.form.subject = response?.subject ?? "";
          this.form.description = response?.description ?? "";
          this.form.language = response?.language ?? null;
          this.form.shortDescription = response?.shortDescription ?? "";
          this.form.isHighlight =
            response?.isHighlight === 0 ? false : true ?? false;
          this.thumbnail = response?.thumbnail ?? {};
          this.file = response?.file ?? {};
        });
    } catch (e) {
      console.error("api error ___", e);
    } finally {
      this.$store.commit("showLoader", false);
    }
  },
  methods: {
    async updateNews() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          if (
            this.form.description.match(/^(<p><br><\/p>)+$/) ||
            this.form.description.replace(/\s+/g, "") == "<p></p>"
          ) {
            this.$refs.simpleRules.setErrors({
              Description: [this.$t("The Description field is required")],
            });
            return;
          }
          await this.$store.dispatch("news/update", {
            id: this.$route.params.id,
            data: {
              ...this.form,
            },
          });
          this.$router.push("/news");
        }
      });
    },
    addFile(event, type) {
      const file = event.target.files[0];
      if (file instanceof Blob) {
        let reader = new FileReader();

        const readFileAsBase64 = () => {
          return new Promise((resolve, reject) => {
            reader.readAsDataURL(file);
            reader.onload = () => {
              const requiredData = reader.result;
              const data = {
                name: file.name,
                size: file.size,
                base64: requiredData,
              };
              resolve(data);
            };
            reader.onerror = (error) => {
              reject(error);
            };
          });
        };
        (async () => {
          try {
            const data = await readFileAsBase64();
            if (type === "thumbnail") this.form.thumbnailImage = data;
            else this.form.file = data;
          } catch (error) {
            console.error("Error reading file:", error);
          }
        })();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#nprogress {
  position: relative;
  z-index: 9999999;
}

.white-color {
  color: white !important;
}

.vgt-responsive {
  overflow-x: visible !important;
}

.news-image {
  margin-top: 10px;
  img {
    width: 200px;
    object-fit: contain;
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 5px;
    border-radius: 5px;
  }
}
</style>
